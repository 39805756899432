import { useNavigate } from 'react-router-dom';

import useAppContext from './useAppContext';

const useNavigateWithLanguage = () => {
  const {
    languageState: { activeLanguage },
  } = useAppContext();
  const navigate = useNavigate();

  const navigateWithLanguage = (path) =>
    navigate(`/${activeLanguage.code}${path}`);

  return navigateWithLanguage;
};

export default useNavigateWithLanguage;
