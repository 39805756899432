import { Box, Button, Typography, alpha, Container, Link } from '@mui/material';

import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';

import Header from '../../../components/Header';
import BookingForm from './BookingForm';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      sx={{
        backgroundImage: 'url(/images/banner.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box bgcolor={alpha('#000', 0.4)} flex={1} display="flex" flexDirection="column">
        <Header />
        <Box p={2} pt={10} flex={1} display="flex" flexDirection="column" alignItems="center">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="900px"
            maxWidth="100vw"
            gap={2}
            px={{ xs: 2, md: 10 }}
          >
            <Box>
              {/* {Array.from({ length: 5 }, (_, i) => i).map((_, i) => (
                <StarIcon key={i} sx={{ color: '#ffe5b1' }} />
              ))} */}
            </Box>
            <Typography color="#ffe5b1" textTransform="uppercase" align="center">
              {t('Common.Slogan')}
            </Typography>
            <Typography
              fontSize={{ xs: '48px', md: '55px' }}
              fontFamily="'Gilda Display', serif"
              color="#ffe5b1"
              align="center"
              textTransform="uppercase"
            >
              {t('Banner.Title')}
            </Typography>
            <Link href="#rooms">
              <Button
                variant="outlined"
                onClick={() => {}}
                sx={{
                  px: 8,
                  py: 1,
                  borderRadius: 2,
                  border: '1px solid #ffe5b1',
                  fontWeight: 400,
                  letterSpacing: 3,
                  bgcolor: alpha('#503E29', 0.6),
                  color: '#ffe5b1',
                  '&:hover': {
                    borderColor: '#ffe5b1',
                  },
                }}
              >
                {t('Common.Rooms')}
              </Button>
            </Link>
          </Box>
        </Box>
        <Box py={4}>
          <Container>
            <BookingForm />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
