import { Box, Container, Typography, Button, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useAppContext from '../../../hooks/useAppContext';

const offers = [
  {
    id: 1,
    name: {
      en: 'Opening Promotion - 68% OFF',
      vi: 'Giảm sốc 68% nhân dịp khai trương',
    },
    description: {
      en: 'All rooms are 10% off.',
      vi: 'Giảm 10% đối với tất cả hạng phòng.',
    },
    image: '/images/offers/1.png',
    url: '/promos/Hotel%20post%202.pdf',
  },
  {
    id: 2,
    name: { en: 'EXCLUSIVE OFFER', vi: 'ƯU ĐÃI ĐỘC QUYỀN' },
    description: {
      en: 'For Deluxe Room, we discount 50% for breakfast.',
      vi: 'Giảm 50% giá bữa sáng đối với phòng Hạng Sang.',
    },
    image: '/images/offers/2.png',
    url: '/promos/AVARI%20Promotion%20Posts.pdf',
  },
  // {
  //   id: 3,
  //   name: { en: '15% OFF for Birthday', vi: 'Sinh nhật giảm 15%' },
  //   description: {
  //     en: 'If your birthday is in this month, we will gift you a 15% discount on total bill.',
  //     vi: 'Các khách có sinh nhật trong tháng sẽ được giảm 15% trên tổng giá trị hoá đơn.',
  //   },
  //   image: '/images/offers/3.png',
  // },
];

const Offers = () => {
  const { t } = useTranslation();
  const {
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/offers/bg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box py={10}>
        <Container>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} mb={4}>
            <Box display="flex" alignItems="center" width="5vw">
              <Box width="4px" height="4px" bgcolor="#FFE5B1" sx={{ transform: 'rotate(45deg)' }} />
              <Box flex={1} height="1px" bgcolor="#FFE5B1" />
            </Box>
            <Typography fontSize="54px" fontFamily="'Gilda Display', serif" color="#FFE5B1">
              {t('Common.Offers')}
            </Typography>
            <Box display="flex" alignItems="center" width="5vw">
              <Box flex={1} height="1px" bgcolor="#FFE5B1" />
              <Box width="4px" height="4px" bgcolor="#FFE5B1" sx={{ transform: 'rotate(45deg)' }} />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={isSm ? 'column' : 'row'}
            justifyContent="center"
            gap={6}
            sx={{ px: isSm ? 4 : 8 }}
          >
            {offers.map((room) => (
              <RoomItem key={room.name.en} room={room} />
            ))}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Offers;

const RoomItem = ({ room }) => {
  const { t } = useTranslation();
  const {
    responsiveState: { isSm, isLg },
    languageState: {
      activeLanguage: { code },
    },
  } = useAppContext();
  const { name, description, image, url } = room;

  return (
    <Box
      height="100%"
      position="relative"
      overflow="hidden"
      sx={{
        '&:hover img': {
          transform: 'scale(1.05)',
        },
        '& img::after': {
          position: 'absolute',
          inset: 0,

          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          content: '""',
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)',
        },
      }}
    >
      <Box borderRadius="32px" overflow="hidden" position="relative">
        <img
          src={image}
          alt="room"
          style={{
            width: '100%',
            aspectRatio: '2/3',
            objectFit: 'cover',
            objectPosition: 'center',
            transition: 'all ease 0.3s',
            position: 'relative',
          }}
        />
      </Box>
      <Box
        className="content"
        display="flex"
        flexDirection="column"
        alignItems={isSm ? 'center' : 'flex-start'}
        gap={2}
        py={2}
        sx={{
          transition: 'all ease 0.3s',
          textAlign: isSm ? 'center' : 'justify',
        }}
      >
        <Typography
          fontFamily="'Gilda Display', serif"
          fontSize="28px"
          color="#FFE5B1"
          lineHeight="28px"
          sx={{ height: isSm ? 'unset' : '56px' }} // 2x lineHeight -> 2 rows
        >
          {name[code]}
        </Typography>
        {/* <Typography
          color="white"
          fontFamily="'Montserrat', sans-serif"
          sx={{ height: isSm ? 'unset' : isLg ? '4.5em' : '3em' }} // lineHeight = 1.5em
        >
          {description[code]}
        </Typography> */}
        <Button
          variant="contained"
          onClick={() => {
            window.location.href = url;
          }}
          sx={{
            px: 8,
            py: 1,
            borderRadius: 0.5,
            fontWeight: 400,
            textTransform: 'none',
            bgcolor: '#ab8a62',
            color: 'white',
            '&:hover': {
              borderColor: '#ffe5b1',
              bgcolor: '#826747',
            },
          }}
        >
          {t('Common.LearnMore')}
        </Button>
      </Box>
    </Box>
  );
};
