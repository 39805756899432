import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import i18n from '../translation/i18n';

const languages = [
  { name: 'English', code: 'en' },
  { name: 'Tiếng Việt', code: 'vi' },
];

const useLanguage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const language = pathname.split('/')[1];

  const activeLanguage = languages.find((item) => item.code === language);

  useEffect(() => {
    if (!activeLanguage) {
      navigate('/en');
    } else if (activeLanguage.code !== i18n.language) {
      i18n.changeLanguage(activeLanguage.code);
    }
  }, [activeLanguage, navigate]);

  const changeLanguage = (newLanguage) => {
    const splittedParts = pathname.split('/');
    splittedParts[1] = newLanguage;

    const newPath = splittedParts.join('/');
    navigate(newPath);
  };

  return { languages, activeLanguage, changeLanguage };
};

export default useLanguage;
