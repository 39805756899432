import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Link,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import parse from 'html-react-parser';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import useAppContext from '../../hooks/useAppContext';
import useNavigateWithLanguage from '../../hooks/useNavigateWithLanguage';
import jobs from './jsons/jobs.json';

dayjs.extend(relativeTime);

const JobDetails = () => {
  const [job, setJob] = useState(null);
  const navigate = useNavigateWithLanguage();
  const { jobId } = useParams();
  const {
    responsiveState: { isSm },
  } = useAppContext();

  useEffect(() => {
    const currentJob = jobs.find(({ id }) => id === jobId);
    if (!currentJob) navigate('');
    else setJob(currentJob);
  }, [navigate, jobId]);

  if (!job) return null;

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/slider/1.jpeg)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
    >
      <Banner job={job} />
      <Box bgcolor="white" py={5}>
        <Container>
          <Box
            display="flex"
            gap={isSm ? 2 : 5}
            flexDirection={isSm ? 'column-reverse' : 'row'}
          >
            <Box
              flex="2"
              sx={{
                '& .job-description *': {
                  textAlign: 'justify',
                  color: '#666',
                },
                '& p': {
                  margin: '1em 0',
                  lineHeight: 1.5,
                },
              }}
            >
              <Typography
                component="h3"
                fontFamily="'Gilda Display', serif"
                fontSize="30px"
                lineHeight="1em"
                textAlign={isSm ? 'center' : 'left'}
                color="#aa8353"
              >
                Job Description
              </Typography>
              <div className="job-description">{parse(job.description)}</div>
              <Box mt={3}>
                <Box height="0.5px" mb={2} width="30%" bgcolor="#aa8353" />
                <Typography component="span" fontFamily="'Barlow', sans-serif">
                  Please submit a resume and cover letter with compensation
                  history to{' '}
                </Typography>
                <Link
                  fontFamily="'Barlow', sans-serif"
                  color="#aa8353"
                  href={`https://mail.google.com/mail/?view=cm&fs=1&to=info@luxuryhotel.com&su=${`Application for ${job.title} | [Your Fullname]`}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  underline="hover"
                >
                  info@luxuryhotel.com
                </Link>
                .
              </Box>
            </Box>
            <Box
              mb={3}
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems={isSm ? 'center' : 'flex-start'}
              sx={{ textAlign: isSm ? 'center' : 'left' }}
              gap={1}
            >
              <Box>
                <Typography fontFamily="'Barlow', sans-serif" color="#aa8353">
                  Openings
                </Typography>
                <Typography fontFamily="'Barlow', sans-serif" color="#666">
                  {job.recruitCount.toString().padStart(2, '0')}
                </Typography>
              </Box>
              <Box>
                <Typography fontFamily="'Barlow', sans-serif" color="#aa8353">
                  Employment Type
                </Typography>
                <Typography fontFamily="'Barlow', sans-serif" color="#666">
                  {job.employmentType}
                </Typography>
              </Box>
              <Box>
                <Typography fontFamily="'Barlow', sans-serif" color="#aa8353">
                  Salary
                </Typography>
                <Typography fontFamily="'Barlow', sans-serif" color="#666">
                  {job.salary}
                </Typography>
              </Box>
              <Box width="fit-content">
                <Typography fontFamily="'Barlow', sans-serif" color="#aa8353">
                  Posted
                </Typography>
                <Tooltip
                  arrow
                  title={dayjs(job.createdAt).format('DD/MM/YYYY HH:mm')}
                >
                  <Typography fontFamily="'Barlow', sans-serif" color="#666">
                    {dayjs(new Date()).diff(dayjs(job.createdAt), 'hour') > 21
                      ? dayjs(job.createdAt).format('DD/MM/YYYY')
                      : dayjs(job.createdAt).fromNow()}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

const Banner = ({ job }) => {
  const {
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Box bgcolor={alpha('#000', 0.4)}>
      <Header pathHighlight="/careers" />
      <Container>
        <Box
          pt={10}
          pb={10}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={isSm ? 'center' : 'flex-start'}
          gap={2}
        >
          <Typography
            component="h2"
            letterSpacing={6}
            fontFamily="'Barlow Condensed', sans-serif"
            fontSize="15px"
            color="white"
          >
            JOB OPENING | {job.department.toUpperCase()}
          </Typography>
          <Typography
            component="h1"
            fontFamily="'Gilda Display', serif"
            fontSize={isSm ? '45px' : '60px'}
            lineHeight="1em"
            color="white"
            textAlign={isSm ? 'center' : 'left'}
          >
            {job.title}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default JobDetails;
