import { Box, Container, Link, Typography } from '@mui/material';

import StaticBanner from '../../components/StaticBanner';
import JobListItem from './components/JobListItem';
import Footer from '../../components/Footer';
import jobs from './jsons/jobs.json';

const Career = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/slider/4.jpeg)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <StaticBanner title="Careers" subTitle="Job Openings" />
      <Box bgcolor="white" py={10}>
        <Container>
          {jobs.map((job, index) => (
            <JobListItem key={job.id} job={job} showTopBorder={index === 0} />
          ))}
          <Box mt={4}>
            <Typography component="span" fontFamily="'Barlow', sans-serif">
              Please submit a resume and cover letter with compensation history
              to{' '}
            </Typography>
            <Link
              fontFamily="'Barlow', sans-serif"
              color="#aa8353"
              href={`https://mail.google.com/mail/?view=cm&fs=1&to=info@luxuryhotel.com&su=${`Application for [Job Title] | [Your Fullname]`}`}
              target="_blank"
              rel="noreferrer noopener"
              underline="hover"
            >
              info@luxuryhotel.com
            </Link>
            .
          </Box>
        </Container>
      </Box>
      {/* <Booking /> */}
      <Footer />
    </Box>
  );
};

export default Career;
