import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';

import './index.css';
import i18n from './translation/i18n';
import App from './App';
import { AppContextProvider } from './contexts/app.context';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ThemeProvider theme={theme}>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
