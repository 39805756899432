import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import useAppContext from '../../../hooks/useAppContext';

dayjs.extend(relativeTime);

export default function JobListItem({ job, showTopBorder }) {
  const {
    languageState: { activeLanguage },
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Box
      component="a"
      href={`/${activeLanguage.code}/careers/${job.id}`}
      borderTop={showTopBorder ? '1px solid #f1eeeb' : 'none'}
      borderBottom="1px solid #f1eeeb"
      p={4}
      display="flex"
      flexDirection={isSm ? 'column' : 'row'}
      alignItems={isSm ? 'flex-start' : 'center'}
      sx={{
        cursor: 'pointer',
        transition: 'all 0.5s ease',
        '& *': { transition: 'all 0.5s ease' },
        '&:hover': { backgroundColor: '#aa8353' },
        '&:hover *': { color: 'white' },
      }}
      gap={1}
    >
      <Box flex="3">
        <Typography
          component="h3"
          fontFamily="'Gilda Display', serif"
          fontSize="24px"
          lineHeight="1.25em"
          color="black"
        >
          {job.title}
        </Typography>
        <Typography fontFamily="'Barlow', sans-serif" color="#666">
          {job.department}
        </Typography>
      </Box>
      <Box flex="2">
        <Typography fontFamily="'Barlow', sans-serif" color="black">
          Employment Type
        </Typography>
        <Typography fontFamily="'Barlow', sans-serif" color="#666">
          {job.employmentType}
        </Typography>
      </Box>
      <Box flex="2">
        <Typography fontFamily="'Barlow', sans-serif" color="black">
          Posted
        </Typography>
        <Typography fontFamily="'Barlow', sans-serif" color="#666">
          {dayjs(new Date()).diff(dayjs(job.createdAt), 'hour') > 21
            ? dayjs(job.createdAt).format('DD/MM/YYYY')
            : dayjs(job.createdAt).fromNow()}
        </Typography>
      </Box>
    </Box>
  );
}
