import { Box, Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import StaticBanner from '../../components/StaticBanner';
import Footer from '../../components/Footer';

const Restaurant = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/slider/4.jpeg)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <StaticBanner
        // title={t('Common.Restaurant')}
        subTitle={t('Restaurant.SubTitle')}
      />
      <Box bgcolor="white" py={10}>
        <Container>
          <Typography>{parse(t('Restaurant.HtmlContent'))}</Typography>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Restaurant;
