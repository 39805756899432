import { useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useTranslation } from 'react-i18next';

import useAppContext from '../hooks/useAppContext';
import navigations from '../utils/navigations';
import HeaderMenu from './HeaderMenu';
import NavigationLinkItem from './NavigationLinkItem';
import HeaderLanguageSelect from './HeaderLanguageSelect';

const Header = ({ pathHighlight }) => {
  const { t } = useTranslation();
  const {
    responsiveState: { isMd },
  } = useAppContext();
  const [open, setOpen] = useState(false);

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: !isMd ? 'center' : 'flex-start',
        position: 'relative',
      }}
    >
      <HeaderMenu open={open} onClose={() => setOpen(false)} />
      <img
        src="/images/logo-avari.svg"
        alt="logo"
        style={{
          width: '128px',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 10,
        }}
      />
      {!isMd ? (
        <Box
          display="flex"
          alignItems="center"
          px={3}
          py={1}
          gap={2}
          sx={{
            position: 'relative',
            zIndex: 1,
            color: '#ffe5b1',
            backgroundImage:
              'linear-gradient(180deg, rgba(80, 62, 41, 0.9) 0%, rgba(80, 62, 41, 0.7) 100%)',
            borderRadius: '0px 0px 16px 16px',
            transition: 'color 0.2s linear',
            '&:before': {
              position: 'absolute',
              content: '""',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage:
                'linear-gradient(180deg, #FFE5B1 0%, #FFFFFF 100%)',
              borderRadius: '0px 0px 16px 16px',
              zIndex: -1,
              transition: 'opacity 0.3s linear',
              opacity: 0,
            },
            '&:hover': {
              color: '#90704b',
              '&:before': {
                opacity: 1,
              },
            },
          }}
        >
          <Box
            width="34vw"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap={2}
          >
            {navigations.slice(0, 4).map((nav) => (
              <NavigationLinkItem
                key={nav.title}
                nav={nav}
                pathHighlight={pathHighlight}
              />
            ))}
          </Box>
          <Box width="128px" />
          <Box
            width="34vw"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap={2}
          >
            {navigations.slice(4).map((nav) => (
              <NavigationLinkItem
                key={nav.title}
                nav={nav}
                pathHighlight={pathHighlight}
              />
            ))}
            <Button
              size="small"
              sx={{
                color: 'white',
                background:
                  'linear-gradient(94deg, #C87F4A 2.33%, rgba(191, 155, 84, 0.9) 52.19%, #C87F4A 100%)',
                '&:hover': { color: '#eee' },
              }}
            >
              {t('Common.ReverseNow')}
            </Button>
            <HeaderLanguageSelect />
          </Box>
        </Box>
      ) : (
        <Box py={2} sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          <MenuOutlinedIcon sx={{ color: 'white' }} />
        </Box>
      )}
    </Container>
  );
};

export default Header;
