import { Box, Container, Grid, Typography } from '@mui/material';
import ConnectingAirportsOutlinedIcon from '@mui/icons-material/ConnectingAirportsOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import BrunchDiningOutlinedIcon from '@mui/icons-material/BrunchDiningOutlined';

const facilities = [
  {
    name: 'Pick Up & Drop',
    description:
      'We’ll pick up from airport while you comfy on your ride, mus nellentesque habitant.',
    icon: ConnectingAirportsOutlinedIcon,
  },
  {
    name: 'Pick Up & Drop',
    description:
      'Fusce tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.',
    icon: DirectionsCarFilledOutlinedIcon,
  },
  {
    name: 'Pick Up & Drop',
    description:
      'Room tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.',
    icon: BedOutlinedIcon,
  },
  {
    name: 'Pick Up & Drop',
    description:
      'Swimming pool tincidunt nise ace park norttito sit space, mus nellentesque habitant.',
    icon: PoolOutlinedIcon,
  },
  {
    name: 'Pick Up & Drop',
    description:
      'Wifi tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.',
    icon: WifiOutlinedIcon,
  },
  {
    name: 'Pick Up & Drop',
    description:
      'Eat tincidunt nisa ace park norttito sit amet space, mus nellentesque habitant.',
    icon: BrunchDiningOutlinedIcon,
  },
];

const Facilities = () => {
  return (
    <Box py={10} bgcolor="white">
      <Container>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            letterSpacing={2}
            fontFamily="'Barlow Condensed', sans-serif"
            fontSize="15px"
            color="#666"
          >
            OUR SERVICES
          </Typography>
          <Typography
            fontFamily="'Gilda Display', serif"
            fontSize="46px"
            lineHeight="60px"
            color="#222"
          >
            Hotel Facilities
          </Typography>
          <Grid container spacing={2}>
            {facilities.map((facility) => (
              <Grid key={facility.name} item xs={12} sm={6} md={4}>
                <FacilityItem facility={facility} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Facilities;

const FacilityItem = ({ facility }) => {
  const { name, description, icon: Icon } = facility;

  return (
    <Box
      width="100%"
      height="100%"
      border="1px solid #f1eeeb"
      p={4}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Icon sx={{ color: '#aa8453', fontSize: 40 }} />
      <Typography
        fontSize="24px"
        fontFamily="'Gilda Display', serif"
        color="#222"
      >
        {name}
      </Typography>
      <Typography
        fontSize="15px"
        fontFamily="'Barlow', sans-serif"
        color="#666"
      >
        {description}
      </Typography>
    </Box>
  );
};
