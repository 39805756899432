import { useState } from 'react';
import { Box, Grid, Button, TextField, Autocomplete } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';

import useAppContext from '../../../hooks/useAppContext';

const BookingForm = ({ mini }) => {
  const { t } = useTranslation();

  const [booking, setBooking] = useState({
    checkin: null,
    checkout: null,
    adults: null,
    children: null,
    rooms: null,
  });

  const changeBooking = (data) => {
    setBooking({ ...booking, ...data });
  };

  const { checkin, checkout, adults, children, rooms } = booking;

  return (
    <Box>
      <Grid
        container
        sx={{
          bgcolor: mini ? 'unset' : 'white',
          borderRadius: mini ? 0 : 8,
          overflow: 'hidden',
          '& .MuiTextField-root': {
            background: 'white',
          },
        }}
        spacing={mini ? 2 : 0}
      >
        <Grid item xs={12} md={mini ? 6 : 2}>
          <Box
            overflow="hidden"
            borderRadius={mini ? 1 : 0}
            px={mini ? 0 : 2}
            sx={{ borderRight: mini ? 'none' : '1px solid #c3b18e' }}
            height={{ xs: '56px', md: '100%' }}
            display="flex"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={(props) => (
                  <BookingTextField
                    {...props}
                    sx={
                      mini
                        ? {
                            '& label': {
                              transform: 'translate(14px, 11px) scale(1)',
                            },
                            '& input': {
                              padding: '10px 0 10px 16px',
                            },
                          }
                        : {}
                    }
                  />
                )}
                label={checkin ? '' : t('BookingForm.CheckIn')}
                value={checkin}
                onChange={(newValue) => {
                  changeBooking({ checkin: newValue });
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item xs={12} md={mini ? 6 : 2}>
          <Box
            overflow="hidden"
            borderRadius={mini ? 1 : 0}
            px={mini ? 0 : 2}
            sx={{ borderRight: mini ? 'none' : '1px solid #c3b18e' }}
            height={{ xs: '56px', md: '100%' }}
            display="flex"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={(props) => (
                  <BookingTextField
                    {...props}
                    sx={
                      mini
                        ? {
                            '& label': {
                              transform: 'translate(14px, 11px) scale(1)',
                            },
                            '& input': {
                              padding: '10px 0 10px 16px',
                            },
                          }
                        : {}
                    }
                  />
                )}
                label={checkout ? '' : t('BookingForm.CheckOut')}
                value={checkout}
                onChange={(newValue) => {
                  changeBooking({ checkout: newValue });
                }}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item xs={12} md={mini ? 4 : 2}>
          <Box
            overflow="hidden"
            borderRadius={mini ? 1 : 0}
            px={mini ? 0 : 2}
            sx={{ borderRight: mini ? 'none' : '1px solid #c3b18e' }}
            height={{ xs: '56px', md: '100%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Autocomplete
              disableClearable
              size="small"
              options={adultOptions}
              onChange={(event, newValue) =>
                changeBooking({ adults: newValue.value })
              }
              value={adultOptions.find((item) => item.value === adults)}
              isOptionEqualToValue={(option, value) => option.value === value}
              renderInput={(params) => (
                <BookingTextField
                  {...params}
                  value={`${adults} ${t('BookingForm.Adults').toLowerCase()}`}
                  label={adults ? '' : t('BookingForm.Adults')}
                  sx={{}}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={mini ? 4 : 2}>
          <Box
            overflow="hidden"
            borderRadius={mini ? 1 : 0}
            px={mini ? 0 : 2}
            sx={{ borderRight: mini ? 'none' : '1px solid #c3b18e' }}
            height={{ xs: '56px', md: '100%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Autocomplete
              disableClearable
              size="small"
              options={childOptions}
              onChange={(event, newValue) =>
                changeBooking({ children: newValue.value })
              }
              value={childOptions.find((item) => item.value === children)}
              isOptionEqualToValue={(option, value) => option.value === value}
              renderInput={(params) => (
                <BookingTextField
                  {...params}
                  value={`${children} ${t('BookingForm.Children').toLowerCase()}`}
                  label={children ? '' : t('BookingForm.Children')}
                  sx={{}}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={mini ? 4 : 2}>
          <Box
            overflow="hidden"
            borderRadius={mini ? 1 : 0}
            px={mini ? 0 : 2}
            height={{ xs: '56px', md: '100%' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Autocomplete
              disableClearable
              size="small"
              options={roomOptions}
              onChange={(event, newValue) =>
                changeBooking({ rooms: newValue.value })
              }
              value={roomOptions.find((item) => item.value === rooms)}
              isOptionEqualToValue={(option, value) => option.value === value}
              renderInput={(params) => (
                <BookingTextField
                  {...params}
                  value={`${rooms} ${t('Common.Rooms').toLowerCase()}`}
                  label={rooms ? '' : t('Common.Rooms')}
                  sx={{}}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={mini ? 12 : 2}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            fullWidth={!mini}
            variant="outlined"
            sx={{
              px: 2.5,
              py: 1.5,
              height: '100%',
              borderRadius: 8,
              borderColor: 'transparent',
              bgcolor: '#c87f4a',
              color: '#ffe5b1',
              fontWeight: 700,
              '&:hover': {
                bgcolor: '#c87f4a',
                borderColor: 'transparent',
              },
            }}
          >
            {t('Common.ReverseNow')} <ArrowForwardIcon sx={{ ml: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingForm;

const adultOptions = Array.from({ length: 4 }, (_, index) => ({
  label: `${index + 1} ${index > 0 ? 'adults' : 'adult'}`,
  value: index + 1,
}));
const childOptions = Array.from({ length: 5 }, (_, index) => ({
  label: `${index} ${index > 1 ? 'children' : 'child'}`,
  value: index,
}));
const roomOptions = Array.from({ length: 4 }, (_, index) => ({
  label: `${index + 1} ${index > 0 ? 'rooms' : 'room'}`,
  value: index + 1,
}));

const BookingTextField = (props) => {
  const {
    responsiveState: { isMd },
  } = useAppContext();
  const color = '#38393d';

  return (
    <TextField
      {...props}
      fullWidth
      InputLabelProps={{ shrink: false }}
      sx={{
        '& *': {
          color: `${color} !important`,
          borderColor: `${color} !important`,
        },
        '& fieldset': { border: 'none' },
        '& label': {
          color: `${color} !important`,
        },
        '& .MuiInputAdornment-positionEnd': {
          borderColor: `${color} !important`,
        },
        '& label.Mui-focused': {
          color: 'white',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: `${color} !important`,
          // borderWidth: '1px',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: `${color} !important`,
          // borderWidth: '1px',
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor: `${color} !important`,
        },
        '& .MuiInput-underline:hover:after': {
          borderBottomColor: `${color} !important`,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: color,
          },
          '&:hover fieldset': {
            borderColor: color,
          },
          '&.Mui-focused fieldset': {
            borderColor: color,
          },
        },
        ...props.sx,
      }}
    />
  );
};
