import { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  alpha,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Slide } from 'react-slideshow-image';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import useAppContext from '../../../hooks/useAppContext';

const clients = [
  '/images/clients/1.png',
  '/images/clients/2.png',
  '/images/clients/3.png',
  '/images/clients/4.png',
  '/images/clients/5.png',
  '/images/clients/6.png',
];

const Booking = () => {
  const {
    responsiveState: { isMd },
  } = useAppContext();

  return (
    <Box bgcolor={alpha('#000', 0.4)}>
      <Container>
        <Box py={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                width="376px"
                maxWidth="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={2}
              >
                <Box>
                  {Array.from({ length: 5 }, (_, i) => i).map((_, i) => (
                    <StarIcon key={i} sx={{ color: '#e4a853' }} />
                  ))}
                </Box>
                <Typography
                  fontSize="24px"
                  fontFamily="'Gilda Display', serif"
                  color="white"
                >
                  Each of our guest rooms feature a private bath, wi-fi, cable
                  television and include full breakfast.
                </Typography>
                <Box pt={2} display="flex" alignItems="center" gap={1}>
                  <PhoneInTalkIcon sx={{ color: 'white', fontSize: 48 }} />
                  <Box>
                    <Typography
                      fontFamily="'Barlow', sans-serif"
                      fontSize="15px"
                      color="white"
                    >
                      Reservation
                    </Typography>
                    <Typography fontSize="27px" color="white" lineHeight="27px">
                      84 123 456 789
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <CheckOutlinedIcon sx={{ color: 'white' }} />
                  <Typography
                    fontSize="13px"
                    fontFamily="'Barlow', sans-serif"
                    color="white"
                  >
                    Call us, it's toll-free.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                bgcolor="#f8f5f0"
                p={5}
                display="flex"
                flexDirection="column"
                gap={2}
                sx={isMd ? {} : { transform: 'translateY(100px)' }}
              >
                <Typography
                  letterSpacing={2}
                  fontFamily="'Barlow Condensed', sans-serif"
                  fontSize="15px"
                  color="#666"
                >
                  ROOMS & SUITES
                </Typography>
                <Typography
                  fontFamily="'Gilda Display', serif"
                  fontSize="27px"
                  lineHeight="30px"
                  color="#222"
                >
                  Hotel Booking Form
                </Typography>
                <Box height="1px" width="100%" bgcolor="#aa845333" />
                <BookingForm />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box
        height="120px"
        bgcolor="white"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Container>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Box>
                  <Slide
                    autoplay
                    prevArrow={<div></div>}
                    nextArrow={<div></div>}
                    duration={700}
                    slidesToShow={3}
                  >
                    {clients.map((item) => (
                      <img src={item} alt="client" style={{ width: '150px' }} />
                    ))}
                  </Slide>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Booking;

const BookingForm = () => {
  const [booking, setBooking] = useState({
    checkin: dayjs(new Date()),
    checkout: dayjs(new Date(Date.now() + 24 * 60 * 60 * 1000)),
    adults: 1,
    children: 0,
  });

  const changeBooking = (data) => {
    setBooking({ ...booking, ...data });
  };

  const { checkin, checkout, adults, children } = booking;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(props) => <BookingTextField {...props} />}
              label="Check in"
              value={checkin}
              onChange={(newValue) => {
                changeBooking({ checkin: newValue });
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(props) => <BookingTextField {...props} />}
              label="Check out"
              value={checkout}
              onChange={(newValue) => {
                changeBooking({ checkout: newValue });
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disableClearable
            size="small"
            options={adultOptions}
            onChange={(event, newValue) =>
              changeBooking({ adults: newValue.value })
            }
            value={adultOptions.find((item) => item.value === adults)}
            isOptionEqualToValue={(option, value) => option.value === value}
            renderInput={(params) => (
              <BookingTextField
                {...params}
                value={`${adults} adults`}
                label="Adults"
                sx={{ '& input': { height: '26px' } }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            disableClearable
            size="small"
            options={childOptions}
            onChange={(event, newValue) =>
              changeBooking({ children: newValue.value })
            }
            value={childOptions.find((item) => item.value === children)}
            isOptionEqualToValue={(option, value) => option.value === value}
            renderInput={(params) => (
              <BookingTextField
                {...params}
                value={`${children} children`}
                label="Children"
                sx={{ '& input': { height: '26px' } }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              px: 2.5,
              py: 1.5,
              borderRadius: 0,
              borderColor: 'transparent',
              bgcolor: '#aa8453',
              color: 'white',
              fontWeight: 400,
              letterSpacing: 3,
              '&:hover': {
                borderColor: 'transparent',
                bgcolor: '#826747',
              },
            }}
          >
            Check availability
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const adultOptions = Array.from({ length: 4 }, (_, index) => ({
  label: `${index + 1} ${index > 0 ? 'adults' : 'adult'}`,
  value: index + 1,
}));
const childOptions = Array.from({ length: 5 }, (_, index) => ({
  label: `${index} ${index > 1 ? 'children' : 'child'}`,
  value: index,
}));

const BookingTextField = (props) => (
  <TextField
    {...props}
    fullWidth
    variant="standard"
    sx={{
      '& *': {
        color: '#222 !important',
        borderColor: '#222 !important',
      },
      '& label': {
        color: '#222 !important',
      },
      '& .MuiInputAdornment-positionEnd': {
        borderColor: '#222 !important',
      },
      '& label.Mui-focused': {
        color: '#222',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#222 !important',
        // borderWidth: '1px',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#222 !important',
        // borderWidth: '1px',
      },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: '#222 !important',
      },
      '& .MuiInput-underline:hover:after': {
        borderBottomColor: '#222 !important',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#222',
        },
        '&:hover fieldset': {
          borderColor: '#222',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#222',
        },
      },
      ...props.sx,
    }}
  />
);
