import { Box, Typography, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import useAppContext from '../hooks/useAppContext';

export default function HeaderLanguageSelect() {
  const {
    languageState: { activeLanguage, languages, changeLanguage },
  } = useAppContext();

  return (
    <Box
      width="3.5em"
      position="relative"
      p={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        cursor: 'pointer',
        '&:hover': { color: '#aa8453' },
        '&:hover .children': { display: 'block' },
      }}
    >
      <Typography sx={{ transition: 'all ease 0.3s' }}>
        {activeLanguage?.code?.toUpperCase()}
      </Typography>
      <KeyboardArrowDownIcon color="inherit" sx={{ fontSize: 16 }} />
      <Box
        className="children"
        position="absolute"
        left={0}
        bottom={0}
        py={2}
        zIndex={99}
        sx={{ display: 'none', transform: 'translateY(100%)' }}
      >
        <Box width="120px" borderRadius={2} color="black" bgcolor="white">
          <Stack direction="column">
            {languages.map((item) => (
              <Box
                key={item.code}
                px={2}
                py={1}
                sx={{
                  cursor: 'pointer',
                  transition: 'all ease 0.3s',
                  '&:hover': { color: '#aa8453' },
                }}
                onClick={() => changeLanguage(item.code)}
              >
                <Typography color="inherit">{item.name}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
