import { Box, Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import StaticBanner from '../../components/StaticBanner';
import Footer from '../../components/Footer';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/slider/4.jpeg)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <StaticBanner
        title={t('Common.Contact')}
        subTitle={t('Contact.SubTitle')}
      />
      <Box bgcolor="white" py={10}>
        <Container>
          <Typography
            sx={{
              '& a': {
                fontFamily: 'Montserrat, sans-serif',
                color: 'black',
              },
              '& a:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {parse(t('Contact.HtmlContent'))}
          </Typography>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Contact;
