import { Box, Typography, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

import useNavigateWithLanguage from '../hooks/useNavigateWithLanguage';

const NavigationLinkItem = ({ nav, pathHighlight }) => {
  const { t } = useTranslation();
  const currentPath = window.location.pathname.slice(3);
  const navigate = useNavigateWithLanguage();

  return (
    <Box
      key={nav.title}
      position="relative"
      display="flex"
      alignItems="center"
      p={1}
      gap={0.25}
      sx={{
        cursor: 'pointer',
        transition: 'all ease 0.3s',
        color:
          currentPath === nav.path || pathHighlight === nav.path
            ? '#aa8353'
            : 'inherit',
        '&:hover': {
          color: '#aa8353',
          '& > .children': { display: 'block' },
        },
      }}
    >
      <Typography
        color="inherit"
        onClick={() => {
          if (nav.children) return;
          navigate(nav.path);
        }}
      >
        {t(nav.title)}
      </Typography>
      {nav.children && (
        <KeyboardArrowDownIcon color="inherit" sx={{ fontSize: 16 }} />
      )}
      {nav.children && (
        <Box
          className="children"
          position="absolute"
          bottom={0}
          left={0}
          py={2}
          zIndex={99}
          sx={{ display: 'none', transform: 'translateY(100%)' }}
        >
          <Box width="180px" borderRadius={2} color="black" bgcolor="#FFE5B1">
            <Stack direction="column">
              {nav.children.map((item) => (
                <Box
                  key={item.title}
                  px={2}
                  py={1}
                  color="#90704B"
                  sx={{
                    cursor: 'pointer',
                    transition: 'all ease 0.3s',
                    '&:hover': { color: '#C87F4A' },
                    '&:hover p': { textDecoration: 'underline' },
                  }}
                  onClick={() => navigate(item.path)}
                >
                  <Typography color="inherit">{t(item.title)}</Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NavigationLinkItem;
