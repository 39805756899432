import { Box } from '@mui/material';

import Banner from './components/Banner';
import Introduction from './components/Introduction';
import Rooms from './components/Rooms';
import ExtraServices from './components/ExtraServices';
import PromotionalVideo from './components/PromotionalVideo';
import Facilities from './components/Facilities';
import Feedbacks from './components/Feedbacks';
import FacilitiyDetails from './components/FacilityDetails';
import Offers from './components/Offers';
import News from './components/News';
import Booking from './components/Booking';
import Footer from '../../components/Footer';

const Home = () => {
  return (
    <Box>
      <Banner />
      <Introduction />
      <Rooms />
      {/* <ExtraServices />
      <PromotionalVideo />
      <Facilities />
      <Feedbacks /> */}
      <FacilitiyDetails />
      <Offers />
      {/* <News /> */}
      {/* <Booking /> */}
      <Footer />
    </Box>
  );
};

export default Home;
