import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import vi from './locales/vi.json';

const pathname = window.location.pathname;
const initLanguage = pathname.split('/')[1];

const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: initLanguage,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
