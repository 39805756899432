const navigations = [
  {
    title: 'Common.Home',
    path: '',
  },
  {
    title: 'Common.About',
    path: '/about',
  },
  {
    title: 'Common.Rooms',
    path: '/rooms',
  },
  {
    title: 'Common.Offers',
    path: '/offers',
  },
  // {
  //   title: 'Careers',
  //   path: '/careers',
  // },
  {
    title: 'Common.Services',
    path: '/services',
    children: [
      { title: 'Common.Restaurant', path: '/restaurant' },
      { title: 'Common.Gym', path: '/gym' },
      { title: 'Common.SwimmingPool', path: '/swimming-pool' },
      { title: 'Common.Laundry', path: '/' },
      { title: 'Common.Shuttle', path: '/' },
    ],
  },
  {
    title: 'Common.Contact',
    path: '/contact',
  },
];

export default navigations;
