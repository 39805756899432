import { Box, Container, Grid, Typography, Button, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useAppContext from '../../../hooks/useAppContext';

const rooms = [
  {
    id: 1,
    name: { en: 'Superior Room', vi: 'Phòng Superior' },
    price: {
      en: { price: 80, currency: '$' },
      vi: { price: 1600000, currency: 'VND' },
    },
    image: '/images/rooms/5.jpeg',
  },
  {
    id: 2,
    name: { en: 'Deluxe Room', vi: 'Phòng Deluxe' },
    price: {
      en: { price: 100, currency: '$' },
      vi: { price: 2000000, currency: 'VND' },
    },
    image: '/images/rooms/Deluxe.jpg',
  },
  {
    id: 3,
    name: { en: 'Premier Room', vi: 'Phòng Premier' },
    price: {
      en: { price: 140, currency: '$' },
      vi: { price: 2800000, currency: 'VND' },
    },
    image: '/images/rooms/Premier.jpg',
  },
  {
    id: 4,
    name: { en: 'Executive Room', vi: 'Phòng Executive' },
    price: {
      en: { price: 150, currency: '$' },
      vi: { price: 3000000, currency: 'VND' },
    },
    image: '/images/rooms/Executive.jpg',
  },
  {
    id: 5,
    name: { en: 'Deluxe Triple Room', vi: 'Phòng Deluxe Triple' },
    price: {
      en: { price: 200, currency: '$' },
      vi: { price: 4000000, currency: 'VND' },
    },
    image: '/images/rooms/DeluxeTriple.jpg',
  },
  {
    id: 6,
    name: { en: 'AVARI Suite-Living Room ', vi: 'Phòng VIP' },
    price: {
      en: { price: 300, currency: '$' },
      vi: { price: 6000000, currency: 'VND' },
    },
    image: '/images/rooms/1.jpeg',
  },
];

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = (number) => formatter.format(number).substring(1);

const Rooms = () => {
  const { t } = useTranslation();

  return (
    <Box
      id="rooms"
      sx={{
        backgroundImage: 'url(/images/rooms.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box py={10} bgcolor={alpha('#000', 0.4)}>
        <Container>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} mb={4}>
            <Box display="flex" alignItems="center" width="5vw">
              <Box width="4px" height="4px" bgcolor="#FFE5B1" sx={{ transform: 'rotate(45deg)' }} />
              <Box flex={1} height="1px" bgcolor="#FFE5B1" />
            </Box>
            <Typography fontSize="54px" fontFamily="'Gilda Display', serif" color="#FFE5B1">
              {t('Common.Rooms')}
            </Typography>
            <Box display="flex" alignItems="center" width="5vw">
              <Box flex={1} height="1px" bgcolor="#FFE5B1" />
              <Box width="4px" height="4px" bgcolor="#FFE5B1" sx={{ transform: 'rotate(45deg)' }} />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <Grid container spacing={2}>
              {/* {rooms.slice(0, 3).map((room) => (
                <Grid key={room.name.en} item xs={12} sm={4}>
                  <RoomItem room={room} />
                </Grid>
              ))}
              {rooms.slice(3, 6).map((room) => (
                <Grid key={room.name.en} item xs={12} sm={4}>
                  <RoomItem room={room} />
                </Grid>
              ))} */}
              {rooms.slice(0, 6).map((room) => (
                <Grid key={room.name.en} item xs={12} sm={6}>
                  <RoomItem room={room} />
                </Grid>
              ))}

              {/* {rooms.slice(5, 6).map((room) => (
                <Grid key={room.name.en} item xs={12} sm={12}>
                  <RoomItem room={room} />
                </Grid>
              ))} */}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Rooms;

const RoomItem = ({ room }) => {
  const { t } = useTranslation();
  const {
    languageState: {
      activeLanguage: { code },
    },
  } = useAppContext();
  const { name, price, image } = room;

  return (
    <Box
      height="100%"
      position="relative"
      overflow="hidden"
      sx={{
        borderTopLeftRadius: '32px',
        borderBottomRightRadius: '32px',
        '&:hover img': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <img
        src={image}
        alt="room"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          transition: 'all ease 0.3s',
        }}
      />
      <Box
        className="content"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
        p={2}
        bgcolor={alpha('#000', 0.25)}
        sx={{ transition: 'all ease 0.3s' }}
      >
        <Typography fontFamily="'Gilda Display', serif" fontSize="28px" color="#FFE5B1" lineHeight="28px">
          {name[code]}
        </Typography>
        <Typography textTransform="uppercase" color="white" fontFamily="'Montserrat', sans-serif">
          {formatCurrency(price[code].price)}
          {price[code].currency} / {t('Common.Night')}
        </Typography>
        <Button
          variant="outlined"
          sx={{
            px: 8,
            py: 1,
            borderRadius: 2,
            border: '1px solid #ffe5b1',
            fontWeight: 400,
            letterSpacing: 3,
            bgcolor: alpha('#503E29', 0.6),
            color: '#ffe5b1',
            '&:hover': {
              borderColor: '#ffe5b1',
            },
          }}
        >
          {t('Common.Book')}
        </Button>
      </Box>
    </Box>
  );
};
