import { createContext } from 'react';

import useLanguage from '../hooks/useLanguage';
import useResponsive from '../hooks/useResponsive';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const languageState = useLanguage();
  const responsiveState = useResponsive();

  return (
    <AppContext.Provider value={{ languageState, responsiveState }}>
      {children}
    </AppContext.Provider>
  );
};
