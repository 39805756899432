import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../pages/Home';
import Career from '../pages/Career';
import JobDetails from '../pages/Career/JobDetails';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Restaurant from '../pages/Restaurant';
import SpaCenter from '../pages/SpaCenter';
import SwimmingPool from '../pages/SwimmingPool';
import Gym from '../pages/Gym';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/:language/careers" element={<Career />} />
      <Route path="/:language/careers/:jobId" element={<JobDetails />} />
      <Route path="/:language/about" element={<About />} />
      <Route path="/:language/contact" element={<Contact />} />
      <Route path="/:language/restaurant" element={<Restaurant />} />
      <Route path="/:language/spa-center" element={<SpaCenter />} />
      <Route path="/:language/swimming-pool" element={<SwimmingPool />} />
      <Route path="/:language/gym" element={<Gym />} />

      <Route path="/:language" element={<Home />} />
      <Route path="*" element={<Navigate to="/vi" replace />} />
    </Routes>
  );
};

export default MainRoutes;
