import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import useAppContext from '../../../hooks/useAppContext';

const facilities = [
  {
    name: { en: 'The Restaurant', vi: 'Nhà Hàng' },
    type: { en: 'DISCOVER', vi: 'KHÁM PHÁ' },
    description: {
      en: 'At LUCIA restaurant located on the 2nd floor, you have the opportunity to enjoy a variety of culinary styles, from Vietnamese dishes, fresh seafood to European and Asian dishes. The restaurant serves breakfast every day and serves an Alacarte menu from 10:00 am to 10:00 pm.',
      vi: 'Tại nhà hàng LUCIA toạ lạc tại tầng 2, Quý khách có cơ hội thưởng thức nhiều phong cách ẩm thực đa dạng, từ những món ăn mang hương vị Việt, các món hải sản tươi ngon đến những món ăn Âu, Á. Nhà hàng là nơi phục vụ bữa sáng hàng ngày và phục vụ thực đơn Alacarte từ 10h00 sáng đến 10h00 tối.',
    },
    image: '/images/facilities/Restaurant.jpg',
  },
  {
    name: { en: 'Meeting Room', vi: 'Phòng hội thảo' },
    type: { en: 'Event', vi: 'Sự Kiện' },
    description: {
      en: "AVARI Hotel is an ideal choice for organizing events and conferences. The meeting room is equipped with a 100-inch LCD screen with high-speed wireless network and a modern sound system.<br />The hotel's two main conference rooms are SUNSHINE MEETINGROOM on the 1st floor and LUCIA MEETINGROOM on the 2nd floor. Each meeting room has an area of ​​nearly 70m2 and can accommodate 70 guests.",
      vi: 'Khách sạn AVARI là địa điểm lựa chọn lí tưởng tổ chức những sự kiện, hội thảo. Phòng họp được trang bị màn hình LCD 100inch với mạng không dây tốc độ cao, hệ thống âm thanh hiện đại.<br />Hai phòng hội nghị chính của khách sạn là SUNSHINE MEETINGROOM tại tầng 1 và LUCIA MEETINGROOM tại tầng 2. Với diện tích mỗi phòng họp gần 70m2 và có sức chứa 70 khách.',
    },
    image: '/images/facilities/MeetingRoom1.jpg',
  },
  {
    name: { en: 'EXERCISE/FITNESS ROOM/GYM', vi: 'Phòng tập' },
    type: { en: 'EXPERIENCES', vi: 'TRẢI NGHIỆM' },
    description: {
      en: 'Located on the 19th floor of the hotel. Equipped with modern exercise equipment such as treadmills, bicycles, multi-function exercise machines and modern support equipment. Served from 06:00 to 21:00.',
      vi: 'Nằm ở tầng 19 của khách sạn. Được trang bị thiết bị tập luyện hiện đại như máy chạy bộ, xe đap, máy tập đa năng và các thiết bị hỗ trợ hiện đại.  Được phục vụ từ 06h00 đến 21h00.',
    },
    image: '/images/facilities/2.jpeg',
  },
  {
    name: { en: 'The Swimming Pool', vi: 'Bể Bơi' },
    type: { en: 'EXPERIENCES', vi: 'TRẢI NGHIỆM' },
    description: {
      en: 'The swimming pool is located on the 20th floor with the gym, and is provided for the enjoyment of our guests. Opening hours are from 6:30 AM to 09:30 PM Children must be accompanied by a guardian. Please familiarise yourself with the posted safety rules.',
      vi: 'Hồ bơi nằm trên tầng 20 với phòng tập thể dục và được cung cấp để phục vụ du khách. Giờ mở cửa từ 6:30 sáng đến 09:30 tối Trẻ em phải có người giám hộ đi cùng. Hãy làm quen với các quy tắc an toàn được đăng.',
    },
    image: '/images/facilities/3.jpeg',
  },
  {
    name: { en: 'Sauna', vi: 'Phòng xông hơi' },
    type: { en: 'EXPERIENCES', vi: 'TRẢI NGHIỆM' },
    description: {
      en: 'The dry sauna and steam bath system is elegantly and luxuriously designed, providing a relaxing place for visitors after stressful and tiring hours. Located on the 19th floor, it is open from 10:00 - 14:00 and 16:00 - 22:00 daily.',
      vi: 'Hệ thống phòng xông hơi khô, xông hơi ướt được thiết kế thanh lịch và sang trọng, là nơi thư giãn cho du khách sau những giờ phút căng thẳng và mệt mỏi. Nằm ở tầng 19, được phục vụ từ 10h00 - 14h00 và 16h00 - 22h00 hàng ngày.',
    },
    image: '/images/facilities/Sauna.jpg',
  },
  {
    name: { en: 'DRY-CLEANING AND LAUNDRY', vi: 'Dịch vụ giặt là' },
    type: { en: 'SERVICES', vi: 'Dịch vụ' },
    description: {
      en: 'Laundry bags and bills are located in your room. Please complete the slip provided, place with the laundry in the plastic bag and contact Housekeeping by dialing number "333" for laundry service.',
      vi: 'Túi giặt và hóa đơn được đặt trong phòng của bạn. Vui lòng điền vào phiếu được cung cấp, cho đồ giặt vào túi nhựa và liên hệ với bộ phận dọn phòng bằng cách quay số "333" để yêu cầu dịch vụ giặt là',
    },
    image: '/images/facilities/4.jpeg',
  },
  {
    name: { en: 'AIRPORT SHUTTLE', vi: 'Đưa đón sân bay' },
    type: { en: 'SERVICES', vi: 'Dịch vụ' },
    description: {
      en: 'The Hotels have a pick-up and drop-off service at the airport. Please contact Reception, Dial “0" for assistance.',
      vi: 'Khách sạn có dịch vụ đón và trả khách tại sân bay. Vui lòng liên hệ Lễ tân, bấm số “0” để được hỗ trợ.',
    },
    image: '/images/facilities/5.jpeg',
  },
];

const FacilitiyDetails = () => {
  const { t } = useTranslation();

  return (
    <Box py={10} bgcolor="white">
      <Container>
        <Box display="flex" alignItems="center" justifyContent="center" gap={2} mb={4}>
          <Box display="flex" alignItems="center" width="5vw">
            <Box width="4px" height="4px" bgcolor="black" sx={{ transform: 'rotate(45deg)' }} />
            <Box flex={1} height="1px" bgcolor="black" />
          </Box>
          <Typography fontSize="54px" fontFamily="'Gilda Display', serif">
            {t('Common.Services')}
          </Typography>
          <Box display="flex" alignItems="center" width="5vw">
            <Box flex={1} height="1px" bgcolor="black" />
            <Box width="4px" height="4px" bgcolor="black" sx={{ transform: 'rotate(45deg)' }} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          {facilities.map((facility, index) => (
            <FacilityItem key={facility.name.en} facility={facility} imageFirst={index % 2 === 0} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FacilitiyDetails;

const FacilityItem = ({ facility, imageFirst }) => {
  const { t } = useTranslation();
  const {
    responsiveState: { isMd },
    languageState: {
      activeLanguage: { code },
    },
  } = useAppContext();
  const { name, type, description, image } = facility;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6} order={imageFirst || isMd ? 0 : 1}>
        <Box
          overflow="hidden"
          width={{ md: 'calc(100% + 5vw)', sm: '100%' }}
          sx={{
            cursor: 'pointer',
            borderTopLeftRadius: '32px',
            borderBottomRightRadius: '32px',
            transform: {
              xs: 'none',
              md: `translateX(${imageFirst ? 0 : '-5vw'})`,
            },
            '&:hover img': {
              transform: `scale(1.15)`,
            },
          }}
        >
          <img
            src={image}
            alt="facility"
            style={{
              display: 'block',
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              transition: 'all ease 0.8s',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
          <Box
            py={4}
            pl={{ xs: 4, md: imageFirst ? '7vw' : 8 }}
            pr={{ xs: 4, md: imageFirst ? 8 : '7vw' }}
            flexShrink={0}
            zIndex={20}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={1}
            border="2px solid #BF9B54"
            sx={{
              borderTopLeftRadius: '32px',
              borderBottomRightRadius: '32px',
            }}
          >
            <Typography fontFamily="'Gilda Display', serif" fontSize="32px" color="#222">
              {name[code]}
            </Typography>
            <Typography fontSize="15px" fontFamily="'Barlow', sans-serif" color="#666">
              {parse(description[code])}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                mt: 2,
                px: 4,
                borderRadius: 2,
                borderColor: 'transparent',
                bgcolor: '#ab8a62',
                color: '#FFE5B1',
                alignSelf: 'flex-start',
                fontWeight: 500,
                textTransform: 'none',
                '&:hover': {
                  borderColor: 'transparent',
                  bgcolor: '#826747',
                },
              }}
            >
              {t('Common.LearnMore')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
