import { Box, Container, Grid, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';

const Introduction = () => {
  const { t } = useTranslation();

  return (
    <Box py={10} bgcolor="white">
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Box>
                {/* {Array.from({ length: 5 }, (_, i) => i).map((_, i) => (
                  <StarIcon key={i} sx={{ color: '#e4a853' }} />
                ))} */}
              </Box>
              <Typography fontFamily="'Montserrat', sans-serif" fontSize="20px" color="#BF9B54">
                {t('Common.Slogan')}
              </Typography>
              <Typography fontFamily="'Gilda Display', serif" fontSize="50px" color="#90704B">
                {t('Introduction.Title')}
              </Typography>
              <Typography fontFamily="'Montserrat', sans-serif" fontSize="14px" color="#38393D">
                {t('Introduction.Content1')}
              </Typography>
              <Typography fontFamily="'Montserrat', sans-serif" fontSize="14px" color="#38393D">
                {t('Introduction.Content2')}
              </Typography>
              <Typography fontFamily="'Montserrat', sans-serif" fontSize="14px" color="#38393D">
                {t('Introduction.Content3')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="space-between" gap={4}>
              <img src="/images/lobby.jpg" alt="rooms" style={{ borderRadius: 8, width: '100%' }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Introduction;
