import { Box, Container, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { pdfjs, Document, Page } from 'react-pdf';
import { useState } from 'react';
import StaticBanner from '../../components/StaticBanner';
import Footer from '../../components/Footer';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const About = () => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    console.log(numPages);
    setNumPages(numPages);
    for (let i = 0; i < 10; i++) {}
  }

  const renderPage = (numPages) => {
    if (!numPages) return;
    var pages = [];
    for (var i = 0; i <= numPages; i++) {
      pages.push(<Page pageNumber={i} scale={2} noData=""></Page>);
    }
    return pages;
  };
  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/slider/4.jpeg)',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <StaticBanner title={t('Common.About')} subTitle={t('About.SubTitle')} />
      <Box bgcolor="white" py={10}>
        <Container>
          <Document file="/pdf/aboutus.pdf" onLoadSuccess={onDocumentLoadSuccess}>
            {renderPage(numPages)}
          </Document>

          {/* <Typography>{parse(t('About.HtmlContent'))}</Typography> */}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default About;
