import { Box, Container, Typography, alpha } from '@mui/material';

import Header from './Header';
import useAppContext from '../hooks/useAppContext';

const Banner = ({ title, subTitle }) => {
  const {
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Box bgcolor={alpha('#000', 0.4)}>
      <Header />
      <Container>
        <Box
          pt={15}
          pb={15}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={isSm ? 'center' : 'flex-start'}
          gap={2}
        >
          <Typography
            component="h2"
            letterSpacing={6}
            fontFamily="'Barlow Condensed', sans-serif"
            fontSize="15px"
            color="white"
            textTransform="uppercase"
          >
            {title}
          </Typography>
          <Typography
            component="h1"
            fontFamily="'Gilda Display', serif"
            fontSize={isSm ? '45px' : '60px'}
            lineHeight="1em"
            color="white"
            textAlign={isSm ? 'center' : 'left'}
          >
            {subTitle}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
