import { useState } from 'react';
import { Drawer, Box, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

import navigations from '../utils/navigations';
import useNavigateWithLanguage from '../hooks/useNavigateWithLanguage';

const HeaderMenu = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithLanguage();
  const [openings, setOpenings] = useState([]);

  const isOpening = (title) => openings.includes(title);
  const toggleOpening = (title) => {
    if (isOpening(title)) {
      setOpenings(openings.filter((item) => item !== title));
    } else {
      setOpenings([...openings, title]);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ width: '100vw' }}
    >
      <Box
        width="100vw"
        height="100vh"
        bgcolor="#f8f5f0"
        p={2}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
          {/* <img src="/images/logo.png" width="120px" alt="logo" /> */}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          {navigations.map((navigation) => (
            <Box key={navigation.title}>
              <Box
                display="flex"
                alignItems="center"
                gap={0.25}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (navigation.children) {
                    toggleOpening(navigation.title);
                  } else {
                    navigate(navigation.path);
                    onClose();
                  }
                }}
              >
                <Typography
                  fontSize={20}
                  fontWeight={400}
                  textTransform="uppercase"
                >
                  {t(navigation.title)}
                </Typography>
                {navigation.children && (
                  <KeyboardArrowDownIcon
                    color="inherit"
                    sx={{ fontSize: 16 }}
                  />
                )}
              </Box>
              {isOpening(navigation.title) && (
                <Box
                  mt={2}
                  ml={2}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  {navigation.children.map((child) => (
                    <Typography
                      fontSize={20}
                      fontWeight={400}
                      textTransform="uppercase"
                      onClick={() => {
                        navigate(child.path);
                        onClose();
                      }}
                    >
                      {t(child.title)}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default HeaderMenu;
