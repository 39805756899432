import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useAppContext from '../hooks/useAppContext';
import BookingForm from '../pages/Home/components/BookingForm';

const footerLinks = [
  { path: '/', key: 'Home' },
  { path: '/about', key: 'About' },
  { path: '/rooms', key: 'Rooms' },
  { path: '/offers', key: 'Offers' },
  { path: '/services', key: 'Services' },
  { path: '/contact', key: 'Contact' },
  { path: '/careers', key: 'Careers' },
];

const contactInfo = {
  address: {
    en: '10 Hoang Dieu, Vinh Nguyen, Nha Trang, Khanh Hoa, Vietnam',
    vi: '10, Hoàng Diệu, P Vĩnh nguyên, Tp Nha trang, Khánh Hòa',
  },
  phone: { en: 'Tel: +84 (0) 2583 663 999', vi: 'SĐT: +84 (0) 2583 663 999' },
};

const copyright = {
  en: '© Copyright 2023 by AVARI',
  vi: '© Bản quyền 2023 thuộc AVARI',
};

// const socials = [
//   { url: 'https://www.instagram.com', Icon: InstagramIcon },
//   { url: 'https://facebook.com', Icon: FacebookIcon },
// ];

const Footer = () => {
  const { t } = useTranslation();
  const {
    languageState: {
      activeLanguage: { code },
    },
  } = useAppContext();

  return (
    <Box bgcolor="#222" pt={8}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            <FooterSectionTitle fontFamily="Fahkwang, serif" fontSize="24px">
              Avari Hotel
            </FooterSectionTitle>
            <FooterSectionBody>{contactInfo.address[code]}</FooterSectionBody>
            <FooterSectionBody>{contactInfo.phone[code]}</FooterSectionBody>
            <a href="mailto:info@avarihotel.com.vn">
              <FooterSectionBody>Email: info@avarihotel.com.vn</FooterSectionBody>
            </a>
          </Grid>
          <Grid item xs={0} md={1} />
          <Grid item xs={12} md={2} sx={{ mb: 3 }}>
            <FooterSectionTitle>Explore</FooterSectionTitle>
            <ul className="list-unstyled">
              {footerLinks.map((link) => (
                <FooterLink key={link.path} href={`/${code}${link.path}`}>
                  {t(`Common.${link.key}`)}
                </FooterLink>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} md={5} sx={{ mb: 3 }}>
            <FooterContactForm />
          </Grid>
        </Grid>
      </Container>
      <Box bgcolor="#38393D" mt={5} p={1}>
        <Typography textAlign="center" lineHeight="1.25em" color="#C3B18E" fontSize="14px">
          {copyright[code]}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

const FooterSectionTitle = (props) => {
  const {
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Typography
      variant="h3"
      fontFamily="'Monserrat', sans-serif"
      textTransform="uppercase"
      textAlign={isSm ? 'center' : 'justify'}
      fontSize="16px"
      lineHeight="1.25em"
      color="rgba(191, 155, 84, 0.9)"
      {...props}
      sx={{
        mb: 1,
        ...props.sx,
      }}
    />
  );
};

const FooterSectionBody = (props) => {
  const {
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Typography
      textAlign={isSm ? 'center' : 'justify'}
      lineHeight="1.75em"
      fontFamily="'Monserrat', sans-serif"
      fontSize="15px"
      color="white"
      {...props}
    />
  );
};

const FooterLink = ({ children, href, ...props }) => (
  <li style={{ marginTop: 3 }}>
    <a href={href}>
      <FooterSectionBody
        sx={{
          transition: 'all 500ms ease',
          position: 'relative',
          '&:before': {
            position: 'absolute',
            top: '55%',
            left: 0,
            bottom: 0,
            width: '6px',
            height: '6px',
            content: '""',
            backgroundColor: '#aa8453',
            transform: 'translateY(-50%)',
            opacity: 0,
            borderRadius: '50%',
            transition: 'all 500ms ease',
          },
          '&:hover': {
            color: '#aa8453',
            paddingLeft: '15px',
            '&:before': { opacity: 1 },
          },
        }}
      >
        {children}
      </FooterSectionBody>
    </a>
  </li>
);

const FooterContactForm = () => {
  const { t } = useTranslation();
  const {
    responsiveState: { isSm },
  } = useAppContext();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      borderRadius={1}
      mt={isSm ? 0 : -4}
      p={4}
      sx={{ background: 'linear-gradient(180deg, #38393D 0%, #C3B18E 100%)' }}
    >
      <Typography
        fontFamily="'Gilda Display', serif"
        fontSize="28px"
        color="#FFE5B1"
        lineHeight="28px"
        textAlign="center"
      >
        {t('BookingForm.Title')}
      </Typography>
      <BookingForm mini />
    </Box>
  );
};

// const FooterSocialLink = ({ Icon, href, ...props }) => (
//   <a href={href} target="_blank" rel="noopener noreferrer">
//     <Icon
//       sx={{
//         fontSize: 18,
//         color: 'white',
//         transition: 'all 500ms ease',
//         '&:hover': {
//           color: '#aa8453',
//         },
//       }}
//     />
//   </a>
// );
